import type { Organization } from '@dreamshaper/ds-interface'

export default defineNuxtRouteMiddleware(async (from) => {
  const { org } = useUser()
  const dsInterface = useDSInterface()

  if (!org.value) {
    const regexOrgId = /^\/org\/([^/]+)\/?/

    const match = from.path.match(regexOrgId)

    if (match) {
      const { data: organization } = await useAsyncData(
        'organization',
        async () =>
          await dsInterface.organization().findOne({
            id: Number(match[1]),
          }),
      )

      if (organization.value?.data && organization.value.statusCode === 200) {
        org.value = organization.value?.data
      } else {
        org.value = {
          id: Number(match[1]),
        } as Organization
      }
    } else {
      return navigateTo('/org')
    }
  }
})
